import React from "react";
import Layout from "../components/layout";

import ServiceTermBody from '../../assets/appInfo/ServiceTerm';

const ServiceTerm = () => {
  return (
    <Layout title="利用規約">
      <p style={{ whiteSpace: 'pre-wrap' }}>{ServiceTermBody}</p>
    </Layout>
  );
};

export default ServiceTerm;
